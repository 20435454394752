<template>
  <div class="blog-post">
    <section class="post">
      <div
        class="container"
        v-for="item in this.post"
        :value="item.id"
        :key="item.id"
      >
        <img class="post-image_main" alt="" :src="item.attributes.banner_url">
        <div class="post-title">
          <h1>{{ item.attributes.title }}</h1>
          <p class="published-in">{{ item.attributes.published_at | moment("DD, MMMM [de] YYYY") }}</p>
        </div>
        <div class="post-content" v-html="item.attributes.content"></div>
      </div>
    </section>
    <div class="divider"></div>
    <section class="news">
      <div class="container">
        <h2>Leia Também</h2>
      </div>
      <div class="container container-news">
        <div
          class="box-news"
          v-for="item in this.posts"
          :value="item.id"
          :key="item.id"
        >
          <img class="news-image" alt="" :src="item.attributes.banner_url">
          <h3>{{ item.attributes.title }}</h3>
          <p>{{ item.attributes.description }}</p>
          <a
            :href="item.attributes.slug"
          >
            Ler mais
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { blogService } from '@/services/blog.service'

export default {
  name: 'BlogPost',
  metaInfo: {
    title: 'imoGo - Blog'
  },
  data () {
    return {
      posts: [],
      post: [],
      currentPage: 1,
      perPage: 3
    }
  },

  mounted () {
    this.getPosts()
    this.showPost(this.$route.params.slug)
  },

  methods: {
    async getPosts () {
      try {
        const pagination = {
          perPage: this.perPage,
          page: this.currentPage
        }

        const posts = await blogService.listPosts(pagination)
        this.posts = posts.data.data.slice(0, 3)
      } catch (error) {
        console.log('Ocorreu um erro inesperado.')
      }
    },

    async showPost (slug) {
      try {
        const post = await blogService.getPost(slug)
        this.post = post.data
        document.head.querySelector('meta[name=description]').content = this.post.data.attributes.description
      } catch (error) {
        console.log('Ocorreu um erro inesperado.')
      }
    }

  }
}
</script>

<style lang="scss" scoped>
  .blog-post {
    padding: 0 0 100px;
    margin-top: 0;

    .container {
      display: flex;
      justify-content: space-between;
      max-width: 1200px;
      align-items: flex-start;

      &.container-news {
        max-width: 1200px;
        margin-top: 62px;
      }
    }

    h2 {
      font-size: var(--size-h2);
      line-height: 48px;
      color: var(--green-custom);
      font-weight: 300;
    }

    .divider {
      width: 95%;
      height: 2px;
      background:  #C4C4C4;
      margin: 100px auto 20px;

    }

    .post {
      padding: 91px 0 0;

      .container {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .post-image_main {
        max-width: 1200px;
        width: 100%;
        object-fit: cover;
        height: 500px;
        border-radius: 30px 0 30px 0;
      }

      .post-title {
        background: var(--white);
        margin: -60px 0 0;
        padding: 48px 48px 48px 105px;
        border-radius: 0 20px 0 0;
        max-width: 1000px;
        width: 100%;

        h1 {
          max-width: 619px;
          font-weight: 700;
          font-size: 40px;
          line-height: 62px;
          font-size: 40px;
          color: var(--secundary-black);
          margin-bottom: 24px;
        }

        .published-in {
          font-weight: 400;
          padding: 0 0;
          color: var(--secundary-black);
        }
      }
    }

    .news {
      padding: 98px 0 0;
    }

    .box-news {
      max-width: 380px;
      width: 100%;

      img {
        width: 100%;
        height: auto;
        margin-bottom: 19px;
        border-radius: 30px;
        height: 254px;
        object-fit: cover;
      }

      h3 {
        margin-bottom: 12px;
        font-weight: 500;
        font-size: var(--size-regular);
        line-height: 29px;
        color: #414F54;
        min-height: 65px;
      }

      p {
        font-weight: 300;
        font-size: var(--size-base);
        line-height: 24px;
        color: #757680;
        margin-bottom: 8px;
        min-height: 70px;
      }

      a {
        text-decoration: none;
        color: var(--maya-blue);
        font-weight: 700;
        font-size: 14px;

        &:hover,
        &:focus,
        &:active {
          color: var(--green-custom);
          animation: all 200ms ease-in-out;
        }
      }
    }

    @media (max-width: 768px) {
      margin-top: 0;
      .post {
        padding: 80px 0 0;
        .post-title {
          margin: 0;
          padding: 16px 0;
          border-radius: 0 0px 0 0;

          h1 {
            margin-bottom: 16px;
            font-weight: 700;
            font-size: 40px;
            line-height: 62px;
          }

          p {
            margin-bottom: 16px;
          }
        }
        p,
        h2,
        ul {
          padding: 0;
        }
      }

      .post-content {
        padding: 0 0 0 0;
      }

      .divider {
        margin: 20px auto 20px;
      }

        .news {
          padding: 20px 0 0;

          .container {
            align-items: flex-start;
            flex-direction: column;

            &.container-news {
              margin-top: 32px;
              flex-direction: column;
            }
          }

          .box-news {
            margin-bottom: 32px;

            &:last-child {
              margin-bottom: 0px;
            }

            p {
              margin-bottom: 16px;
            }
          }
        }
    }
  }
</style>
